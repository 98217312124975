import React from "react"
import "../styles/global.scss"
import * as styles from "../styles/Services.module.scss"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as Scroll from "react-scroll"

const Services = ({ name }) => {
  const data = useStaticQuery(graphql`
    query Services {
      file(
        relativePath: {
          eq: "projects/project1/TsolakisArchitects_WhiteCoastMilos_final-51_medium.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            tracedSVGOptions: {
              color: "#86ce8f"
              alphaMax: 1.5
              threshold: 140
            }
          )
        }
      }
    }
  `)

  const bgImage = convertToBgImage(data.file.childImageSharp.gatsbyImageData)
  delete bgImage.fluid.src
  delete bgImage.fluid.srcSet
  delete bgImage.fluid.Webp
  delete bgImage.fluid.srcWebp
  delete bgImage.fluid.srcSetWebp

  const { t } = useTranslation()

  return (
    <Scroll.Element name={name}>
      <BackgroundImage className={styles.container} {...bgImage}>
        <div className={styles.innerContainer}>
          <div className={styles.textContainer}>
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{ __html: t("services.content") }}
            ></div>
          </div>
          <div className={styles.headerWrapper}>
            <div className={styles.headerContainer}>
              <h1 className={styles.header}>{t("services.header")}</h1>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </Scroll.Element>
  )
}

export default Services
