import React from "react"
import "../styles/global.scss"
import Menu from "../components/Menu"
import MobileMenu from "../components/MobileMenu"
import Homepage from "../components/Homepage"
import Philosophy from "../components/Philosophy"
import Services from "../components/Services"
import Profile from "../components/Profile"
import BlackBackground from "../components/BlackBackground"
import Contact from "../components/Contact"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import { SEO } from "../components/SEO"
import * as Scroll from "react-scroll"

const News = loadable(() => import("../components/News"))
const Projects = loadable(() => import("../components/Projects"))

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const Home = ({ location }) => {
  const origin = location.state ? location.state.origin : null

  const [width, setWidth] = React.useState(isBrowser ? window.innerWidth : 0)

  React.useEffect(() => {
    function handleResize() {
      if (isBrowser) setWidth(window.innerWidth)
    }
    if (isBrowser) window.addEventListener("resize", handleResize)
  })

  React.useEffect(() => {
    if (origin) {
      Scroll.scroller.scrollTo(origin, {
        spy: true,
        smooth: true,
        duration: 0,
      })
    }
  }, [origin])

  return isBrowser ? (
    <>
      <h1 hidden></h1>
      {width >= 1220 ? <Menu /> : <MobileMenu />}
      <Homepage width={width} name="homepage" />
      <Philosophy name="philosophy" />
      <Services name="services" />
      <Profile width={width} name="profile" />
      <Scroll.Element style={{ minHeight: "100vh" }} name="project"><Projects width={width} name="projects" /></Scroll.Element>
      <Scroll.Element style={{ minHeight: "100vh" }} name="news"><News width={width} name="news" /></Scroll.Element>
      <Contact width={width} name="contact" />
      <BlackBackground />
    </>
  ) : (
    <></>
  )
}

export const Head = ({ pageContext }) => {
  return pageContext.language === "en" ? (
    <>
      <SEO
        title="Onarland - Landscape Architecture"
        description="Onarland is a new business in the field of landscape architecture and undertakes private and public landscapes projects."
        language="en-gb"
      />
      <meta
        property="og:description"
        content="Onarland is a new business in the field of landscape architecture."
      />
      <meta
        property="og:image"
        content="https://i.ibb.co/7NDGgrp/onarland-preview.png"
      />
    </>
  ) : (
    <>
      <SEO
        title="Onarland - Αρχιτεκτονική Τοπίου"
        description="Η Onarland είναι μία νέα επιχείρηση αρχιτεκτονικής τοπίου και αναλαμβάνει μελέτες διαμόρφωσης περιβάλλοντος χώρου για ιδιωτικά και δημόσια έργα."
        language="gr"
      />
      <meta
        property="og:description"
        content="Η Onarland είναι μία νέα επιχείρηση αρχιτεκτονικής τοπίου."
      />
      <meta
        property="og:image"
        content="https://i.ibb.co/7NDGgrp/onarland-preview.png"
      />
    </>
  )
}

export default Home

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
