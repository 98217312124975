// extracted by mini-css-extract-plugin
export var mobileBar = "MobileMenu-module--mobileBar--EQ2Ra";
export var mobileCompanyName = "MobileMenu-module--mobileCompanyName--ZnI-G";
export var mobileElement = "MobileMenu-module--mobileElement--qpY6Y";
export var mobileElementSeparator = "MobileMenu-module--mobileElementSeparator--svK-E";
export var mobileGlobeContent = "MobileMenu-module--mobileGlobeContent--jXHBM";
export var mobileGlobeContentContainer = "MobileMenu-module--mobileGlobeContentContainer--GrBGd";
export var mobileGoBack = "MobileMenu-module--mobileGoBack--IyLUp";
export var mobileIcon = "MobileMenu-module--mobileIcon--daxxg";
export var mobileIconContainer = "MobileMenu-module--mobileIconContainer--db2rU";
export var mobileLogo = "MobileMenu-module--mobileLogo--u54X2";
export var mobileLonelyElement = "MobileMenu-module--mobileLonelyElement--6Lve4";
export var mobileMenu = "MobileMenu-module--mobileMenu--fEoCT";
export var mobileMenuContainer = "MobileMenu-module--mobileMenuContainer--hOGTb";