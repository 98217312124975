// extracted by mini-css-extract-plugin
export var address = "Contact-module--address--qqyQ1";
export var container = "Contact-module--container--p8HvI";
export var email = "Contact-module--email--NEFRs";
export var form = "Contact-module--form---2KHT";
export var formLabel = "Contact-module--formLabel--3DiLv";
export var formSubmit = "Contact-module--formSubmit--3gimA";
export var formSubmitContainer = "Contact-module--formSubmitContainer--MPpSy";
export var formSubmitUnavailableMessage = "Contact-module--formSubmitUnavailableMessage--7K3IQ";
export var header = "Contact-module--header--CwV2q";
export var infoContainer = "Contact-module--infoContainer--Qw3hd";
export var innerContainer = "Contact-module--innerContainer--gYUUx";
export var phone = "Contact-module--phone--24GEC";
export var social = "Contact-module--social--2sXnX";
export var socialsContainer = "Contact-module--socialsContainer--pinxt";
export var textInput = "Contact-module--textInput--191is";
export var textareaInput = "Contact-module--textareaInput--YcwwH";
export var wrapper = "Contact-module--wrapper--YzUll";