import React from "react"

export const SEO = ({ title, description, language }) => {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta http-equiv='content-language' content={language}></meta>
    </>
  )
}
