import React from "react"
import * as styles from "../styles/BlackBackground.module.scss"

const BlackBackground = () => {
  return (
    <div className={styles.background}>
      <p className={styles.copyright}>
        &copy;&nbsp;
        <span> 2022-{new Date().getFullYear()} Onarland. All Rights Reserved.</span>
      </p>
    </div>
  )
}

export default BlackBackground
