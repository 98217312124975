import React from "react"
import "../styles/global.scss"
import * as styles from "../styles/Profile.module.scss"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as Scroll from "react-scroll"

const Profile = ({ width, name }) => {
  const data = useStaticQuery(graphql`
    query Profile {
      background: file(relativePath: { eq: "profile_background.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
      photo: file(relativePath: { eq: "profile_photo.png" }) {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `)

  const bgImage = convertToBgImage(
    data.background.childImageSharp.gatsbyImageData
  )
  delete bgImage.fluid.src
  delete bgImage.fluid.srcSet
  delete bgImage.fluid.Webp
  delete bgImage.fluid.srcWebp
  delete bgImage.fluid.srcSetWebp

  const { t } = useTranslation()

  return (
    <Scroll.Element name={name}>
      <BackgroundImage className={styles.container} {...bgImage}>
        <div className={styles.innerContainer}>
          {width >= 1220 ? (
            <div
              className={styles.textContainer}
              dangerouslySetInnerHTML={{ __html: t("profile.content") }}
            ></div>
          ) : (
            <></>
          )}
          <div className={styles.photoContainer}>
            <div className={styles.photoSubContainer}>
              <GatsbyImage
                className={styles.photo}
                image={data.photo.childImageSharp.gatsbyImageData}
                alt={data.photo.name}
              />
              <div>
                <p>{t("profile.photo.title")}</p>
                <p style={{ marginBottom: 0 }}>
                  {t("profile.photo.subtitle1")}
                </p>
                <p style={{ marginTop: 0 }}>{t("profile.photo.subtitle2")}</p>
              </div>
            </div>
          </div>
          {width < 1220 ? (
            <div
              className={styles.textContainer}
              dangerouslySetInnerHTML={{ __html: t("profile.content") }}
            ></div>
          ) : (
            <></>
          )}
        </div>
      </BackgroundImage>
    </Scroll.Element>
  )
}

export default Profile
