import React from "react"
import "../styles/global.scss"
import * as styles from "../styles/Homepage.module.scss"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import * as Scroll from "react-scroll"

const Homepage = ({ width, name }) => {
  const data = useStaticQuery(graphql`
    query Homepage {
      backgrounds: allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "homepage" }
        }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              tracedSVGOptions: { alphaMax: 1.5, threshold: 130 }
            )
          }
        }
      }
      logo: file(relativePath: { eq: "logo_black_with_name.png" }) {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      arrow: file(relativePath: { eq: "down-arrow.png" }) {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `)

  const bgImage = convertToBgImage(
    data.backgrounds.nodes[0].childImageSharp.gatsbyImageData
  )
  delete bgImage.fluid.src
  delete bgImage.fluid.srcSet
  delete bgImage.fluid.Webp
  delete bgImage.fluid.srcWebp
  delete bgImage.fluid.srcSetWebp

  return (
    <Scroll.Element name={name}>
      <BackgroundImage className={styles.container} {...bgImage}>
        <GatsbyImage
          className={styles.logo}
          image={data.logo.childImageSharp.gatsbyImageData}
          alt={data.logo.name}
        />
        <Scroll.Link
          to="philosophy"
          spy={true}
          smooth={true}
          duration={500}
          offset={width >= 1220 ? 0 : -50}
        >
          <GatsbyImage
            className={styles.icon}
            image={data.arrow.childImageSharp.gatsbyImageData}
            alt={data.arrow.name}
          />
        </Scroll.Link>
      </BackgroundImage>
    </Scroll.Element>
  )
}

export default Homepage
