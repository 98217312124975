import React from "react"
import * as styles from "../styles/MobileMenu.module.scss"
import * as Scroll from "react-scroll"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useTranslation, useI18next, I18nextContext } from "gatsby-plugin-react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeftLong, faGlobe } from "@fortawesome/free-solid-svg-icons"

const MobileMenu = ({ type }) => {
  const data = useStaticQuery(graphql`
    query Mobilemenu {
      menu: file(relativePath: { eq: "menu.png" }) {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      cancel: file(relativePath: { eq: "cancel.png" }) {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      companyName: file(relativePath: { eq: "company-name.png" }) {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `)

  const [showMobileMenu, setShowMobileMenu] = React.useState(false)
  const [aboutSelected, setAboutSelected] = React.useState(false)

  const { languages, changeLanguage } = useI18next()
  const context = React.useContext(I18nextContext)
  const { t } = useTranslation()

  if (type === undefined) {
    return (
      <div>
        <div className={styles.mobileBar}>
          <Scroll.Link to="homepage" spy={true} smooth={true} duration={500}>
            <GatsbyImage
              className={styles.mobileLogo}
              image={data.logo.childImageSharp.gatsbyImageData}
              alt={data.logo.name}
            />
          </Scroll.Link>
          <GatsbyImage
            className={styles.mobileCompanyName}
            image={data.companyName.childImageSharp.gatsbyImageData}
            alt={data.companyName.name}
          />
          <div
            className={styles.mobileIconContainer}
            onClick={() => {
              setShowMobileMenu(!showMobileMenu)
              setAboutSelected(false)
            }}
          >
            <GatsbyImage
              className={styles.mobileIcon}
              image={
                showMobileMenu
                  ? data.cancel.childImageSharp.gatsbyImageData
                  : data.menu.childImageSharp.gatsbyImageData
              }
              alt={showMobileMenu ? data.cancel.name : data.menu.name}
            />
          </div>
        </div>
        <div
          style={showMobileMenu ? { display: "flex" } : { display: "none" }}
          className={styles.mobileMenuContainer}
        >
          {!aboutSelected ? (
            <div className={styles.mobileMenu}>
              <div className={styles.mobileGlobeContentContainer}>
                {languages.map(language => (
                  <div
                    className={styles.mobileGlobeContent}
                    onClick={() => changeLanguage(language)}
                    key={language}
                  >
                    {language}
                  </div>
                ))}
              </div>
              <div className={styles.mobileElementSeparator}></div>
              <div
                className={styles.mobileElement}
                onClick={() => setAboutSelected(true)}
              >
                {t("menu.about.text")}
              </div>
              <div className={styles.mobileElementSeparator}></div>
              <Scroll.Link
                className={styles.mobileElement}
                to="projects"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                onClick={() => setShowMobileMenu(false)}
              >
                {t("menu.projects.text")}
              </Scroll.Link>
              <div className={styles.mobileElementSeparator}></div>
              <Scroll.Link
                className={styles.mobileElement}
                to="news"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                onClick={() => setShowMobileMenu(false)}
              >
                {t("menu.news.text")}
              </Scroll.Link>
              <div className={styles.mobileElementSeparator}></div>
              <Scroll.Link
                className={styles.mobileElement}
                to="contact"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                onClick={() => setShowMobileMenu(false)}
              >
                {t("menu.contact.text")}
              </Scroll.Link>
            </div>
          ) : (
            <div className={styles.mobileMenu}>
              <Scroll.Link
                className={styles.mobileElement}
                to="philosophy"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                onClick={() => {
                  setShowMobileMenu(false)
                  setAboutSelected(false)
                }}
              >
                {t("menu.about.philosophy.text")}
              </Scroll.Link>
              <div className={styles.mobileElementSeparator}></div>
              <Scroll.Link
                className={styles.mobileElement}
                to="services"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                onClick={() => {
                  setShowMobileMenu(false)
                  setAboutSelected(false)
                }}
              >
                {t("menu.about.services.text")}
              </Scroll.Link>
              <div className={styles.mobileElementSeparator}></div>
              <Scroll.Link
                className={styles.mobileElement}
                to="profile"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                onClick={() => {
                  setShowMobileMenu(false)
                  setAboutSelected(false)
                }}
              >
                {t("menu.about.profile.text")}
              </Scroll.Link>
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div className={styles.mobileBar}>
          <Link to="/">
            <GatsbyImage
              className={styles.mobileLogo}
              image={data.logo.childImageSharp.gatsbyImageData}
              alt={data.logo.name}
            />
          </Link>
          <GatsbyImage
            className={styles.mobileCompanyName}
            image={data.companyName.childImageSharp.gatsbyImageData}
            alt={data.companyName.name}
          />
          <Link to={context.language === "en" ? "/" : "/gr"} state={{ origin: type }}>
            <FontAwesomeIcon
              className={styles.mobileGoBack}
              icon={faArrowLeftLong}
            />
          </Link>
        </div>
      </div>
    )
  }
}

export default MobileMenu
