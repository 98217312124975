import React from "react"
import "../styles/global.scss"
import * as styles from "../styles/Contact.module.scss"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as Scroll from "react-scroll"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faInstagram,
  faPinterest,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"

const Contact = ({ width, name }) => {
  const data = useStaticQuery(graphql`
    query Contact {
      file(relativePath: { eq: "contact_background.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
    }
  `)

  const bgImage = convertToBgImage(data.file.childImageSharp.gatsbyImageData)
  delete bgImage.fluid.src
  delete bgImage.fluid.srcSet
  delete bgImage.fluid.Webp
  delete bgImage.fluid.srcWebp
  delete bgImage.fluid.srcSetWebp

  const { t } = useTranslation()

  const containerStyle = {
    width: width >= 1450 ? "705px" : width >= 1220 ? "569px" : "250px",
    height: width >= 1450 ? "425px" : width >= 1220 ? "411px" : "201px",
  }

  const center = {
    lat: 37.99594462816395,
    lng: 23.77696910065526,
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDVC3v3_i2KFEUJmoU0Zw6rBxYHShHK90g",
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds()
    // map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const socials = (
    <div className={styles.socialsContainer}>
      <a
        className={styles.social}
        href="https://www.facebook.com/onarland.landscapearchitecture"
        target="_blank"
        aria-label="Facebook"
      >
        <FontAwesomeIcon icon={faFacebook} />
      </a>
      <a
        className={styles.social}
        href="https://www.instagram.com/onar_land/"
        target="_blank"
        aria-label="Instagram"
      >
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a
        className={styles.social}
        href="https://www.linkedin.com/company/onarland/"
        target="_blank"
        aria-label="LinkedIn"
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a
        className={styles.social}
        href="https://www.pinterest.com/onarland/"
        target="_blank"
        aria-label="Pinterest"
      >
        <FontAwesomeIcon icon={faPinterest} />
      </a>
    </div>
  )

  return (
    <Scroll.Element name={name}>
      <BackgroundImage className={styles.wrapper} {...bgImage}>
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <h1 className={styles.header}>{t("contact.header")}</h1>
            <div className={styles.infoContainer}>
              {/* <form
                className={styles.form}
                name="contact"
                method="POST"
                data-netlify-recaptcha="true"
                netlify-honeypot="bot-field"
                data-netlify="true"
              >
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                  <label>
                    Don’t fill this out if you’re human:{" "}
                    <input name="bot-field" />
                  </label>
                </p>
                <label className={styles.formLabel} htmlFor="name">
                  {t("contact.form.name")}
                </label>
                {width >= 1220 ? <br></br> : <></>}
                <input
                  className={styles.textInput}
                  type="text"
                  id="name"
                  name="name"
                />
                {width >= 1220 ? <br></br> : <></>}
                <br></br>
                <label className={styles.formLabel} htmlFor="email">
                  {t("contact.form.email")}
                </label>
                {width >= 1220 ? <br></br> : <></>}
                <input
                  className={styles.textInput}
                  type="text"
                  id="email"
                  name="email"
                />
                {width >= 1220 ? <br></br> : <></>}
                <br></br>
                <label className={styles.formLabel} htmlFor="subject">
                  {t("contact.form.subject")}
                </label>
                {width >= 1220 ? <br></br> : <></>}
                <input
                  className={styles.textInput}
                  type="text"
                  id="subject"
                  name="subject"
                />
                {width >= 1220 ? <br></br> : <></>}
                <br></br>
                <label className={styles.formLabel} htmlFor="message">
                  {t("contact.form.message")}
                </label>
                {width >= 1220 ? <br></br> : <></>}
                <textarea
                  className={styles.textareaInput}
                  id="message"
                  name="message"
                />
                {width >= 1220 ? <br></br> : <></>}
                <br></br>
                <div className={styles.formSubmitContainer}>
                  <div data-netlify-recaptcha="true"></div>
                  <input
                    disabled
                    className={styles.formSubmit}
                    type="submit"
                    value={t("contact.form.submit")}
                  />
                  <p className={styles.formSubmitUnavailableMessage}>
                    {t("contact.form.unavailable")}
                  </p>
                </div>
              </form> */}
              <div className={styles.address}>
                <div>
                  <p>
                    <b>{t("contact.info.address.header")}:</b>{" "}
                    {t("contact.info.address.content")}
                  </p>
                  <p>
                    <b>{t("contact.info.telephone.header")}:</b>{" "}
                    <a
                      className={styles.phone}
                      href={`tel:${t("contact.info.telephone.number1")}`}
                    >
                      {t("contact.info.telephone.number1")}
                    </a>
                    {", "}
                    <a
                      className={styles.phone}
                      href={`tel:${t("contact.info.telephone.number1")}`}
                    >
                      {t("contact.info.telephone.number2")}
                    </a>
                    {", "}
                    <a
                      className={styles.phone}
                      href={`tel:${t("contact.info.telephone.number2")}`}
                    >
                      {t("contact.info.telephone.number3")}
                    </a>
                  </p>
                  <p>
                    <b>{t("contact.info.email.header")}:</b>{" "}
                    <a
                      className={styles.email}
                      href={`mailto:${t("contact.info.email.content")}`}
                    >
                      {t("contact.info.email.content")}
                    </a>
                  </p>
                </div>
                {/* {width >= 1220 ? socials : <></>} */}
                {socials}
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={15}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                  >
                    <Marker position={center} label={"Onarland"} />
                  </GoogleMap>
                ) : (
                  <></>
                )}
              </div>
              {/* {width < 1220 ? socials : <></>} */}
            </div>
          </div>
        </div>
      </BackgroundImage>
    </Scroll.Element>
  )
}

export default Contact
